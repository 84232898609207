import React from 'react'
import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
type BlockNoteEditorProps = {
  value?: string
  setValue?: Function
  onlyView?: boolean
}
export function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const BlockNoteRichText = ({ value, onlyView }: BlockNoteEditorProps) => {
  return <EditorContainer content={value} disable={onlyView} isTask={true} />
}

export default React.memo(BlockNoteRichText)
