import { clearTokens, setTokens } from '@src/modules/auth/utils/token'
import socketManager from '../socket/socketManager'
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { publicAxios } from '../utils/axios'
import { store } from '../store'

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_APP_BASE_URL as string,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('access_token')
    const user = store.getState().auth.user
    const x_workspace = localStorage.getItem('currentWorkspace') || user?.lastWorkspaceUsed

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('x-workspace', x_workspace as string)
      socketManager.joinWorkSpace(x_workspace)
    }
    return headers
  },
})

let isRefreshing = false
let refreshPromise: any = null

const staggeredBaseQueryWithBailOut = retry(async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    if (result.error.status === 401) {
      const currentWorkspace = localStorage.getItem('currentWorkspace')

      if (!isRefreshing) {
        isRefreshing = true

        refreshPromise = publicAxios
          .get('/auth/refresh', {
            headers: { 'x-workspace': currentWorkspace },
          })
          .then((response) => {
            const { accessToken } = response.data.payload
            setTokens(accessToken)
          })
          .catch(() => {
            clearTokens()
            window.location.replace('/')
          })
          .finally(() => {
            isRefreshing = false
          })
      }
      await refreshPromise
      result = await baseQuery(args, api, extraOptions)
      return result
    }
    retry.fail(result.error?.data)
  }

  return result
})
const baseQueryWithRetry = staggeredBaseQueryWithBailOut

export const api = createApi({
  reducerPath: 'tamApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
})
