import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
import useDebounce from '@src/modules/shared/hooks/useDebounce'

import { useUpdateTaskDescriptionMutation } from '@src/modules/tasks/services/tasksApi'

import { useState } from 'react'

function EditorTaskDescription({ taskId, taskDescription, markdownDescription }) {
  const [updateTask] = useUpdateTaskDescriptionMutation()
  const [value, setValue] = useState('')
  useDebounce(
    () => {
      if (JSON.stringify(value) === taskDescription) return
      updateTask({
        id: taskId,
        description: JSON.stringify(value),
        ...(markdownDescription ? { markdownDescription: null } : {}),
      })
    },
    500,
    [value],
  )
  return (
    <div>
      <EditorContainer
        taskId={taskId}
        content={taskDescription}
        markdownDescription={markdownDescription}
        isTaskDescription={true}
        isTask={true}
        setContent={(value) => {
          setValue((prev) => (JSON.stringify(prev) !== JSON.stringify(value) ? value : prev))
        }}
      />
    </div>
  )
}

export default EditorTaskDescription
