import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
const AddComment = ({ comment }: { comment: any }) => {
  return (
    <div className="add_comment_action">
      <EditorContainer isTask={true} content={comment?.content} disable={true} />
    </div>
  )
}

export default AddComment
