import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import historyIcon from '../assets/icons/history.svg'
import DescriptionHistory from './DescriptionHistory'
import { useGetDescriptionHistoryQuery } from '@src/modules/tasks/services/notificationApi'
import EditorTaskDescription from '../components/EditorTaskDescription'

type Props = {
  taskId: string
  taskDescription: any
  task?: TaskType & { markdownDescription: string }
}

function Description({ taskId, taskDescription = 'null', task }: Props) {
  const { t } = useTranslation(['document'])
  const isFirstRender = useRef(true)
  const [description, _] = useState(taskDescription)
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  //   const parseDescription = (taskDescription) => {
  //     if (typeof taskDescription === 'string') {
  //       try {
  //         const parsed = JSON.parse(taskDescription)
  //         if (Array.isArray(parsed) && parsed.length > 0 && parsed[0].type) {
  //           return parsed
  //         } else if (parsed && typeof parsed === 'object' && parsed.root) {
  //           return parsed
  //         } else {
  //           return taskDescription
  //         }
  //       } catch (error) {
  //         console.error('Failed to parse description:', error)
  //         return taskDescription
  //       }
  //     } else if (
  //       Array.isArray(taskDescription) &&
  //       taskDescription.length > 0 &&
  //       taskDescription[0].type
  //     ) {
  //       return taskDescription
  //     } else if (taskDescription && typeof taskDescription === 'object' && taskDescription?.root) {
  //       return taskDescription
  //     } else {
  //       return taskDescription
  //     }
  //   }
  //   const getEditorContent = () => {
  //     if (task?.markdownDescription) {
  //       return null
  //     }
  //     if (parsedDescription) {
  //       return parsedDescription
  //     }
  //     return null
  //   }

  const { data } = useGetDescriptionHistoryQuery({
    taskId,
    skip: 0,
    limit: 2,
  })
  //   const [value, setValue] = useState('')
  //   console.log(value)
  //   useDebounce(
  //     () => {
  //       updateTask({ id: taskId, description: JSON.stringify(value) })
  //     },
  //     500,
  //     [value],
  //   )

  return (
    <div className="description" style={{ flexDirection: 'row' }}>
      {data?.payload?.length > 0 && (
        <div className="show-history" onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
          <img src={historyIcon} />
          <span>{t('Show history')}</span>
        </div>
      )}

      <DescriptionHistory setIsHistoryOpen={setIsHistoryOpen} isHistoryOpen={isHistoryOpen} />
      <div className="blockNoteContainer">
        <EditorTaskDescription
          taskId={taskId}
          taskDescription={description}
          markdownDescription={task?.markdownDescription}
        />
        {/* <BlockNoteRichText
          value={restor ? restor : description}
          setValue={handleDescriptionChange}
          focus={true}
          onUplaod={addTaskAttachment}
          key={restor}
        /> */}
      </div>
    </div>
  )
}

export default Description
