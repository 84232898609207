import { Avatar, Dropdown, message } from 'antd'
import {
  useAddEmojiReplyMutation,
  useDeleteEmojiReplyMutation,
  useDeleteReplyMutation,
  useUpdateEmojiReplyMutation,
  useUpdateReplyMutation,
} from '../../services/memberAnswerApi'

import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { ReactComponent as DeleteSVG } from '../../assets/icons/answers/delete.svg'
import { ReactComponent as EditSVG } from '../../assets/icons/answers/edit.svg'
import Picker from '@emoji-mart/react'
import { ReactComponent as ReactionSVG } from '../../assets/icons/answers/reaction.svg'
import data from '@emoji-mart/data'
import { deleteReplyToAnswer } from '../../data/answers/answersSlice'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { useAppSelector } from '@src/modules/shared/store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'

type OriginalObjectType = {
  code: string
  commentId: string
  createdAt: string
  deletedAt: string | null
  id: string
  type: string
  updatedAt: string
  userId: string
}

type GroupedObjectType = {
  type: string
  count: number
  emoji: string
  myReaction: { exist: boolean; id: string }
  users: memberType[]
}

export function groupByType(
  originalArray: OriginalObjectType[],
  currentUserId: string,
  users: memberType[],
): GroupedObjectType[] {
  const groupedMap = new Map<string, GroupedObjectType>()
  Array.isArray(originalArray) &&
    originalArray?.forEach((obj) => {
      const { type, code, userId } = obj
      const user = users?.find((user) => user?.id === userId)
      const reactionInfo = originalArray?.find(
        (reaction) => reaction?.type === type && currentUserId === reaction?.userId,
      )
      if (groupedMap.has(type)) {
        const existingGroup = groupedMap.get(type)!
        existingGroup.count += 1
        existingGroup.emoji = code
        existingGroup.users.push(user)
      } else {
        const newGroup: GroupedObjectType = {
          type,
          count: 1,
          emoji: code,
          myReaction: {
            exist: !!reactionInfo,
            id: reactionInfo?.id,
          },
          users: [user],
        }
        groupedMap.set(type, newGroup)
      }
    })

  const groupedArray = Array.from(groupedMap.values())
  return groupedArray
}

function formatDateString(inputDateString: string) {
  const { t } = useTranslation(['document'])
  const lang = localStorage.getItem('i18nextLng')
  const inputDate = new Date(inputDateString)
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - inputDate.getTime()

  if (timeDifference < 24 * 60 * 60 * 1000) {
    if (timeDifference < 60 * 60 * 1000) {
      if (timeDifference < 60 * 1000) {
        const secondsDifference = Math.floor(timeDifference / 1000)
        return secondsDifference === 0
          ? t('Just now')
          : lang == 'ar'
            ? `${t('ago')} ${Math.abs(secondsDifference)} ${t('seconds')} `
            : `${secondsDifference} ${t('seconds')} ${t('ago')}`
      } else {
        const minutesDifference = Math.floor(timeDifference / (60 * 1000))
        return lang == 'ar'
          ? ` ${t('ago')} ${minutesDifference} ${t('min')}`
          : `${minutesDifference} ${t('min')} ${t('ago')}`
      }
    } else {
      const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000))
      return lang == 'ar'
        ? `${t('ago')} ${hoursDifference} ${t('hours')} `
        : `${hoursDifference} ${t('hours')} ${t('ago')}`
    }
  } else if (timeDifference < 2 * 24 * 60 * 60 * 1000) {
    return t('Yesterday')
  } else if (timeDifference < 7 * 24 * 60 * 60 * 1000) {
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
    return `${daysDifference} ${t('days')} ${t('ago')}`
  } else {
    const options: any = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return inputDate.toLocaleDateString('en-US', options)
  }
}

interface MembersListProps {
  members: memberType[]
}

export const MembersList = ({ members }: MembersListProps) => {
  return (
    <div className="ant-dropdown-menu reaction-dropdown">
      {members?.map((member) => {
        const memberName = member?.fullName || member?.email
        return (
          <div className="reaction-user">
            <Avatar size={'small'} src={member?.avatar}>
              {memberName && memberName[0]?.toUpperCase()}
            </Avatar>
            <ArabicElement tag="p" content={memberName} tooltip />
          </div>
        )
      })}
    </div>
  )
}

const Reply = ({ reply, users, questionId = '' }) => {
  const { user } = useAppSelector((state) => state.auth)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [newComment, setComment] = useState<string>('')
  const [ShowEmoji, setShowEmoji] = useState<boolean>(false)
  const [addEmoji] = useAddEmojiReplyMutation()
  const [updateEmoji] = useUpdateEmojiReplyMutation()
  const [deleteEmoji] = useDeleteEmojiReplyMutation()
  const [updateReply, { isLoading }] = useUpdateReplyMutation()
  const [deleteReply] = useDeleteReplyMutation()
  const { t } = useTranslation(['document'])

  const updateReplyHandler = () => {
    if (newComment === reply?.content) {
      message.error(t('text_is_same'))
      return
    }
    updateReply({ commentId: reply?.id, content: newComment, questionId })
      .unwrap()
      .then(() => setEditMode(false))
      .catch((err) => {
        message.error(err?.message || 'error')
      })
  }

  const deleteReplyHandler = () => {
    deleteReply({ id: reply?.id, answerId: reply?.answerId, questionId })
      .unwrap()
      .catch((err) => message?.error(err?.message || 'Error on delete reply'))
    deleteReplyToAnswer({ id: reply?.id, answerId: reply?.answerId })
  }

  const reactions = groupByType(reply?.reactions, user?.user?.id, users)

  const manageEmoji = (url: string, emojiType) => {
    const myReaction = reactions?.find((reaction) => reaction?.myReaction?.exist)
    if (!myReaction) {
      addEmoji({
        commentId: reply?.id,
        code: url,
        type: emojiType,
        answerId: reply?.answerId,
        questionId,
      })
    } else if (myReaction?.type === emojiType) {
      deleteEmoji({
        id: myReaction?.myReaction.id,
        commentId: reply?.id,
        answerId: reply?.answerId,
        questionId,
      })
    } else if (myReaction?.type !== emojiType) {
      updateEmoji({
        id: myReaction?.myReaction.id,
        code: url,
        type: emojiType,
        answerId: reply?.answerId,
        questionId,
        commentId: reply?.id,
      })
    }
  }

  return (
    <div className="answer-replies-item">
      <div className="reply-content">
        <Avatar src={reply?.owner?.avatar} size={'small'}>
          {reply?.owner?.fullName && reply?.owner?.fullName[0].toUpperCase()}
        </Avatar>

        <div className="reply-content_body">
          <div className="reply-content-title">
            <div className="reply-user">
              {reply?.owner?.fullName}{' '}
              <h4>
                {t('Commented')} {reply?.edited ? 'Edited' : null}
              </h4>
            </div>
            <div className="reply-info">
              <div className="reply-reactions">
                {reactions?.map((reaction, i) => {
                  return (
                    <Dropdown dropdownRender={() => <MembersList members={reaction?.users} />}>
                      <div
                        className={`reply-reaction ${
                          reaction?.myReaction?.exist ? 'active-reaction' : ''
                        }`}
                        key={i}
                        onClick={() => manageEmoji(reaction?.emoji, reaction.type)}
                      >
                        <span>{reaction?.emoji}</span>
                        <span>{reaction?.count}</span>
                      </div>
                    </Dropdown>
                  )
                })}
                <Dropdown
                  dropdownRender={() => (
                    <Picker
                      data={data}
                      categories={['frequent', 'people', 'nature', 'foods', 'activity']}
                      onEmojiSelect={(emoji) => {
                        manageEmoji(emoji.native, emoji?.unified)
                        setShowEmoji(false)
                      }}
                    />
                  )}
                  trigger={['click']}
                  open={ShowEmoji}
                  onOpenChange={(visible) => setShowEmoji(visible)}
                >
                  <ReactionSVG />
                </Dropdown>
              </div>
              <span>{formatDateString(reply.createdAt)}</span>
            </div>
          </div>
          <div className="reply-content-description">
            <EditorContainer
              isTask={true}
              content={reply?.content}
              setContent={(value) => setComment(JSON.stringify(value))}
              disable={!editMode}
            />
            {user.user.id === reply?.ownerId && (
              <div className={`edit-reply-buttons ${editMode ? 'edit-mode' : ''}`}>
                <div>
                  <EditSVG onClick={() => setEditMode(!editMode)} />
                  <DeleteSVG onClick={deleteReplyHandler} />
                </div>
                {editMode ? (
                  <div>
                    <div className="submit-button" onClick={() => setEditMode(false)}>
                      Cancel
                    </div>
                    <div className="submit-button" onClick={updateReplyHandler}>
                      {isLoading ? 'Loading...' : 'Save'}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reply
