import axiosInstance from '@src/modules/shared/utils/axios'
interface UploadResponse {
  id: string
  url: string
}

export const uploadImage = (file): Promise<UploadResponse> => {
  const formData = new FormData()
  formData.append('files', file)
  return axiosInstance
    .post(`/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return { id: response.data.payload[0].id, url: response.data.payload[0].location, success: 1 }
    })
    .catch((error) => {
      console.error('Upload failed', error)
      return {
        success: 0,
        url: null,
        id: null,
      }
    })
}
