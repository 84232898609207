import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { ReactComponent as DescriptionsIcon } from './../../assets/icons/task/descriptions.svg'
import { Popover } from 'antd'
import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
import { isEditorEmpty } from '../../utils/isEditorEmpty'

interface TaskDescriptionPreview {
  task: TaskType
}

const TaskDescriptionPreview = ({ task }: TaskDescriptionPreview) => {
  const description = task?.description
  if (isEditorEmpty(description)) return null
  if (!description) return null

  return (
    <div className="description-preview">
      <Popover
        placement={'leftTop'}
        overlayInnerStyle={{
          maxHeight: '400px',
          maxWidth: '400px',
        }}
        overlayClassName="description-preview-popover"
        content={<EditorContainer content={description} disable={true} isTask={true} />}
      >
        <DescriptionsIcon />
      </Popover>
    </div>
  )
}

export default TaskDescriptionPreview
