import { useTranslation } from 'react-i18next'

const Star = ({ index, rating, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    onClick={onClick}
  >
    <g clip-path="url(#clip0_4884_152007)">
      <path
        d="M5.33918 3.15485C6.07806 1.82937 6.4475 1.16663 6.99984 1.16663C7.55217 1.16663 7.92161 1.82937 8.66049 3.15484L8.85165 3.49776C9.06162 3.87442 9.1666 4.06275 9.33029 4.18701C9.49398 4.31128 9.69784 4.3574 10.1056 4.44965L10.4768 4.53364C11.9116 4.85828 12.629 5.0206 12.7997 5.56947C12.9704 6.11834 12.4813 6.69025 11.5031 7.83408L11.2501 8.13001C10.9721 8.45505 10.8331 8.61757 10.7706 8.81863C10.7081 9.01969 10.7291 9.23653 10.7711 9.6702L10.8094 10.065C10.9572 11.5911 11.0312 12.3542 10.5843 12.6934C10.1375 13.0326 9.46578 12.7234 8.12236 12.1048L7.7748 11.9448C7.39305 11.769 7.20217 11.6811 6.99984 11.6811C6.79751 11.6811 6.60663 11.769 6.22487 11.9448L5.87731 12.1048C4.5339 12.7234 3.86219 13.0326 3.41534 12.6934C2.96849 12.3542 3.04243 11.5911 3.19032 10.065L3.22858 9.6702C3.2706 9.23652 3.29161 9.01969 3.22909 8.81863C3.16657 8.61757 3.02759 8.45505 2.74962 8.13001L2.49656 7.83408C1.5184 6.69025 1.02932 6.11834 1.2 5.56947C1.37068 5.0206 2.08809 4.85828 3.5229 4.53364L3.8941 4.44965C4.30183 4.3574 4.5057 4.31128 4.66939 4.18701C4.83308 4.06275 4.93806 3.87442 5.14803 3.49776L5.33918 3.15485Z"
        fill="white"
        stroke="#FFAB00"
        className={`star ${rating > index ? 'filled' : ''}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_4884_152007">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const defaultSelectedStars = 5

function Rating({ formik }) {
  const { t } = useTranslation(['customFields'])
  const rating = formik?.values?.values?.stars || defaultSelectedStars
  const handelChange = (index) => {
    formik.setFieldValue('values.stars', index)
  }

  return (
    <div className="star-rating">
      <div className="star-rating-header">
        <span className="label">{t('number')}</span>
        <span className="rating">
          {rating} {rating > 1 ? t('stars') : t('star')}
        </span>
      </div>

      <div className="star-rating-stars">
        <div>
          {Array.from({ length: 5 }, (_, index) => (
            <Star
              key={index}
              index={index}
              rating={rating}
              onClick={() => handelChange(index + 1)}
            />
          ))}
        </div>
        <div className={'star-rating-stars-container'}>
          {Array.from({ length: 5 }, (_, index) => (
            <span key={index}> {index + 1}</span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Rating
